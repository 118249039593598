import { installRegisteredApps } from '@wix/members-area-integration-kit';
import { EditorType, ExportsFn } from '@wix/editor-platform-sdk-types';

import {
  GROUPS_APP_DEFINITION_ID,
  PAID_PLANS_APP_DEFINITION_ID,
} from '../../constants';

import type { EditorMigratePayload } from './types';

export const exports: ExportsFn = (sdk, params) => {
  const { initialAppData } = params;
  const isADI = params.origin.type === EditorType.ADI;

  return {
    public: {},
    private: {},
    editor: {
      async appInstalled(data) {
        if (data.appDefinitionId === initialAppData.appDefinitionId) {
          if (!isADI) {
            await setupPages();
            await installRegisteredApps();
            await openLandingPage();
          }
        }
      },
      async migrate(payload?: EditorMigratePayload) {
        if (payload?.withPricingPlans) {
          const isInstalled = await sdk.document.tpa.isApplicationInstalled(
            GROUPS_APP_DEFINITION_ID,
            { appDefinitionId: PAID_PLANS_APP_DEFINITION_ID },
          );

          if (isInstalled) {
            return;
          }

          await sdk.document.tpa.add.application(GROUPS_APP_DEFINITION_ID, {
            shouldNavigate: false,
            appDefinitionId: PAID_PLANS_APP_DEFINITION_ID,
          });
        }
      },
    },
  };

  async function openLandingPage() {
    const pageRef = await sdk.tpa.getPageRefByTPAPageId(
      GROUPS_APP_DEFINITION_ID,
      {
        tpaPageId: 'group',
      },
    );

    return sdk.document.pages.navigateTo(GROUPS_APP_DEFINITION_ID, { pageRef });
  }

  /**
   * Setup managing app so pages will be grouped in pages panel
   * Behavior is described in manifest
   */
  async function setupPages() {
    const managingAppDefId = initialAppData.appDefinitionId;

    const [groupPageRef, groupListPageRef] = await Promise.all([
      sdk.tpa.getPageRefByTPAPageId(GROUPS_APP_DEFINITION_ID, {
        tpaPageId: 'group',
      }),
      sdk.tpa.getPageRefByTPAPageId(GROUPS_APP_DEFINITION_ID, {
        tpaPageId: 'groups',
      }),
    ]);

    await Promise.all([
      sdk.document.pages.data.update(GROUPS_APP_DEFINITION_ID, {
        pageRef: groupPageRef,
        data: { managingAppDefId, isLandingPage: true },
      }),
      sdk.document.pages.data.update(GROUPS_APP_DEFINITION_ID, {
        pageRef: groupListPageRef,
        data: { managingAppDefId },
      }),
    ]);
  }
};
